import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ApiLoadError from '@/utils/error/ApiLoadError';
import { ApiResource } from '@/types/resources/ApiResource';
import ResourceInfoMixin from '@/mixins/ResourceInfoMixin';

@Component
export default class LoadResourceMixin extends mixins(ResourceInfoMixin) {
  protected resource: ApiResource | null = null;

  protected apiLoadError: ApiLoadError | null = null;

  async loadResource() {
    try {
      const response = await this.$api.get(
        `/api/${this.resourceTitleFromRoute}/${this.resourceIdFromRoute}?locale=${this.$root.$i18n.locale}&groups[]=translations`,
      );
      if (response.status === 200) {
        this.resource = response.data;
      } else {
        this.apiLoadError = new ApiLoadError(response.data);
      }
    } catch (e) {
      this.apiLoadError = new ApiLoadError(e.message);
    }
  }

  async mounted() {
    await this.loadResource();
  }

  @Watch('$route.params')
  @Watch('$root.$i18n.locale')
  async reload() {
    await this.loadResource();
  }
}
