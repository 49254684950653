





























import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import LoadResourceMixin from '@/mixins/LoadResourceMixin';
import NonExistentEditForm from '@/utils/error/NonExistentEditForm';
import { ApiResource } from '@/types/resources/ApiResource';
import EditForm from '@/components/resources/EditForm.vue';

@Component({
  components: { EditForm },
})
export default class EditResource extends mixins(LoadResourceMixin) {
  private editForm: string | NonExistentEditForm | null = null;

  goBack() {
    if (window) {
      window.history.back();
    }
  }

  backToList() {
    this.$router.push({
      name: 'list-resource',
      params: { resourceTitle: this.$route.params.resourceTitle },
    });
  }

  /**
   * This function gets the edited resource and updates the local
   * copy of it (it reloads the edited resource).
   * TODO: This function is currently unused in favor if `backToList()`
   * because it is not working as expected.
   */
  async updateResource(resource: ApiResource) {
    if (resource['@id']) {
      const response = await this.$api.get(resource['@id'], {
        params: {
          'groups[]': 'translations',
          locale: this.$root.$i18n.locale,
        },
      });
      if (response.status === 200) {
        this.resource = response.data;
      }
    } else {
      this.resource = resource;
    }
  }

  get editFormComponentExists() {
    return !(this.editForm instanceof NonExistentEditForm);
  }

  @Watch('resource')
  loadEditForm(value: ApiResource) {
    const resourceName = value['@context'].split('/').reverse()[0];

    this.editForm = `${resourceName}EditForm`;

    // Test if EditForm component exists and is registered
    if (this.$options?.components && !this.$options.components[this.editForm]) {
      // eslint-disable-next-line no-console
      console.error(`The Edit Component for this resource is missing or is not called "${this.editForm}"`);
      this.editForm = new NonExistentEditForm();
    }
  }
}
