








































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { ApiResource } from '@/types/resources/ApiResource';
import { Violation, ViolationMapping } from '@/types/shared/Violations';
import { EditFormInterface } from '@/types/interfaces/EditResource/EditFormInterface';
import { mixins } from 'vue-class-component';
import ResourceInfoMixin from '@/mixins/ResourceInfoMixin';

@Component({
  components: { ValidationObserver },
})
export default class EditForm extends mixins(ResourceInfoMixin) {
  @Prop({ type: Object, required: true }) resource!: ApiResource;

  @Prop({ type: String, required: true }) editFormType!: string;

  private saving = false;

  private success: null | boolean = null;

  private backendErrors: string[] = [];

  public $refs!: {
    editForm: InstanceType<typeof ValidationObserver>;
    editComponent: Vue & EditFormInterface;
  }

  async submitResource() {
    this.saving = true;

    // Access prepareLocalResource from Children
    const resourceToPut = await this.$refs.editComponent.prepareLocalResource();

    if (resourceToPut === null) {
      this.$refs.editForm.setErrors({ name: this.$t('critical_error') as string });
      return;
    }

    try {
      let response;
      const headers: { [key: string]: string } = {};

      if (resourceToPut instanceof FormData) {
        headers['Content-Type'] = 'multipart/form-data';
      }

      if (this.$route.query.duplicate) {
        const match = this.resource['@id'].match(/\/api\/\w+/);
        if (match?.length) {
          response = await this.$api.post(match[0], resourceToPut, {
            headers,
          });
        }
      } else {
        response = await this.$api.put(this.resource['@id'], resourceToPut, {
          headers,
        });
      }

      if (response?.status === 200) {
        this.$emit('resource-updated', response.data);
        this.saving = false;
        this.showSuccess();
        this.$refs.editForm.reset();
      } else if (response?.status === 201) {
        this.$router.push({
          name: 'list-resource',
          params: { resourceTitle: this.$route.params.resourceTitle },
        });
      }
    } catch (e) {
      if (e.response?.data['@type'] === 'ConstraintViolationList') {
        const violations: ViolationMapping = {};
        e.response.data.violations.forEach((v: Violation) => {
          violations[v.propertyPath] = [v.message];
        });
        this.$refs.editForm.setErrors(violations);
        this.backendErrors = e.response.data['hydra:description'].split('\n');
      }
      this.saving = false;
    }
  }

  showSuccess() {
    this.success = true;
    setTimeout(() => {
      this.success = null;
      this.$refs.editForm.reset();
      this.$refs.editComponent.resetLocalResource();
    }, 1000);
  }

  async mounted() {
    await this.$refs.editComponent.loadLocalResource();
  }
}
